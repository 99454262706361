import { createReducer } from 'redux-starter-kit';
import actions from '../actions/index';

const initialState = {
  percent: 0,
  images: [],
  isEmpty: true,
};

export default createReducer(initialState, {
  [actions.createArticlePhotos]: (state, action) => {
    return { ...state, percent: action.payload };
  },
  [actions.getArticlePhotos]: (state, action) => {
    return { ...state, images: action.payload };
  },
  [actions.updateArticlePhotos]: (state, action) => {
    return { ...state, isEmpty: action.payload };
  },
});
