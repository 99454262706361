import { createAction } from 'redux-starter-kit';

export default {
  createCategory: createAction('CREATE_CATEGORY'),
  getCategories: createAction('GET_CATEGORIES'),
  deleteCategory: createAction('DELETE_CATEGORY'),
  updateCategory: createAction('UPDATE_CATEGORY'),
  createSubCategory: createAction('CREATE_SUB_CATEGORY'),
  getSubCategories: createAction('GET_SUBcATEGORIES'),
  deleteSubCategory: createAction('DELETE_SUB_CATEGORY'),
  updateSubCategory: createAction('UPDATE_SUB_CATEGORY'),
  createArticle: createAction('CREATE_ARTICLE'),
  deleteArticle: createAction('DELETE_ARTICLE'),
  updateArticle: createAction('UPDATE_ARTICLE'),
  getArticles: createAction(' GET_ARTICLES'),
  currentUser: createAction('CURRENT_USER'),
  errors: createAction('ERRORS'),
  auth: createAction('AUTH'),
  createArticlePhotos: createAction('CREATE_ARTICLE_PHOTOS'),
  getArticlePhotos: createAction('GET_ARTICLE_PHOTOS'),
  deleteArticlePhotos: createAction('DELETE_ARTICLE_PHOTOS'),
  createArticleVideos: createAction('CREATE_ARTICLE_VIDEOS'),
  getArticleVideos: createAction('GET_ARTICLE_VIDEOS'),
  deleteArticleVideos: createAction('DELETE_ARTICLE_VIDEOS'),
  articleProgressEvent: createAction('ARTICLE_PROGRESS_EVENT'),
  getArticlesById: createAction('GET_ARTICLES_BYID'),
  getArticlesByUser: createAction('GET_ARTICLES_BY_USER'),
  getArticlesBySubCategoryId: createAction('GET_ARTICLES_BY_SUBCATEGORY_ID'),
  updateArticlePhotos: createAction('UPDATE_ARTICLE_PHOTOS'),
};
