import thunk from "redux-thunk";
import { configureStore } from "redux-starter-kit";
import categoriesReducer from "./reducers/categoriesReducer";
import authReducer from "./reducers/authReducer";
import currentUserReducer from "./reducers/currentUserReducer";
import errorReducer from "./reducers/errorReducer";
import subCategoriesReducer from "./reducers/subCategoriesReducer";
import articleReducer from "./reducers/articleReducer";
import articlePhotos from "./reducers/articlePhotosReducer";
import articleVideos from "./reducers/articleVideosReducer";

const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    subCategories: subCategoriesReducer,
    auth: authReducer,
    currentUser: currentUserReducer,
    errors: errorReducer,
    articles: articleReducer,
    articlePhotos: articlePhotos,
    articleVideos: articleVideos
  },
  middleware: [thunk]
});

export default store;
