import { createReducer } from "redux-starter-kit";
import actions from "../actions/index";

export default createReducer([], {
  [actions.createCategory]: (state, action) => {
    return [...state, action.payload];
  },
  [actions.getCategories]: (state, action) => {
    return action.payload;
  },
  [actions.deleteCategory]: (state, action) => {
    return state.filter(x => x._id !== action.id);
  },
  [actions.updateCategory]: (state, action) => {
    return [action.payload, ...state.filter(x => x._id !== action.payload._id)];
  }
});
