/* eslint-disable no-use-before-define */
import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "../setAuthToken";
import actions from "./index";
import { toast } from "react-toastify";
import api from "../api";

export const registerUser = user => async dispatch => {
  try {
    await axios.post(`${api}/users/register`, user);
  } catch (err) {
    toast.error(err.message);
  }
};

export const loginUser = user => async dispatch => {
  try {
    const res = await axios.post(`${api}/users/login`, user);
    const { token } = res.data;
    localStorage.setItem("jwtToken", token);
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch(setCurrentUser(decoded));
  } catch (err) {
    toast.error(err.message);
  }
};

// export const loginUser = user =>  dispatch => {
//   axios
//     .post(`${api}/users/login`, user)
//     .then(res => {
//       const { token } = res.data;
//       localStorage.setItem("jwtToken", token);
//       setAuthToken(token);
//       const decoded = jwt_decode(token);
//       dispatch(setCurrentUser(decoded));
//     })
//     .catch(err => {
//       dispatch({
//         type: actions.errors,
//         payload: err.response.data
//       });
//     });
// };

export const setCurrentUser = decoded => ({
  type: actions.auth,
  payload: decoded
});

// export const getCurrentUser = () => dispatch => {
//   axios
//     .get(`${api}/users/me`)
//     .then(res => {
//       dispatch(getCurrentUserSuccess(res.data));
//     })
//     .catch(err => {
//       throw err;
//     });
// };

// export const getCurrentUserSuccess = user => ({
//   type: GET_CURRENT_USER,
//   user,
// });

export const logoutUser = () => dispatch => {
  localStorage.removeItem("jwtToken");
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
