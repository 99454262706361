import { createReducer } from "redux-starter-kit";
import isEmpty from "../is-empty";
import actions from "../actions/index";

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default createReducer(initialState, {
  [actions.auth]: (state, action) => ({
    ...state,
    isAuthenticated: !isEmpty(action.payload),
    user: action.payload
  })
});
