import { createReducer } from "redux-starter-kit";
import actions from "../actions/index";

const initialState = {
  percent: 0,
  videos: []
};

export default createReducer(initialState, {
  [actions.createArticleVideos]: (state, action) => {
    return { ...state, percent: action.payload };
  },
  [actions.getArticleVideos]: (state, action) => {
    return { ...state, videos: action.payload };
  }
});
