import React, { Suspense, useEffect, lazy } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import setAuthToken from './setAuthToken';
import store from './store';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import { setCurrentUser, logoutUser } from './actions/authentication';
import CircularProgress from '@material-ui/core/CircularProgress';

const HomeContainer = lazy(() => import('./containers/HomeContainer'));
const NewsItemContainer = lazy(() => import('./containers/NewsItemContainer'));
const CategoriesView = lazy(() => import('./containers/CategoriesView'));
const SubCategoriesView = lazy(() => import('./containers/SubCategoriesView'));
const About = lazy(() => import('./components/About'));
const Privacy = lazy(() => import('./components/Privacy'));
const ArticleView = lazy(() => import('./containers/ArticleView'));
const ArticleEditView = lazy(() => import('./containers/ArticleEditView'));
const ArticlePhotosView = lazy(() => import('./containers/ArticlePhotosView'));
const ArticleVideosView = lazy(() => import('./containers/ArticleVideosView'));
const ArticlesByCategory = lazy(() =>
  import('./containers/ArticlesByCategoryView')
);
const ArticleByIdView = lazy(() => import('./containers/ArticleByIdView'));

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
}

function Routes() {
  const auth = useSelector(state => state.auth);

  return (
    <Suspense
      fallback={
        <CircularProgress variant="indeterminate" style={{ marginTop: '5%' }} />
      }
    >
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <HomeContainer {...props} />}
          />
          <Route
            exact
            path="/article/:id"
            render={props => <NewsItemContainer {...props} />}
          />
          <Route exact path="/about" render={props => <About {...props} />} />
          <Route
            exact
            path="/privacy"
            render={props => <Privacy {...props} />}
          />
          <Route
            exact
            path="/articlesbysubcategory/:id"
            render={props => <ArticlesByCategory {...props} />}
          />
          {auth.isAuthenticated ? (
            <>
              <Route
                exact
                path="/admin/categories"
                render={props => <CategoriesView {...props} />}
              />
              <Route
                exact
                path="/admin/subcategories"
                render={props => <SubCategoriesView {...props} />}
              />
              <Route
                exact
                path="/admin/articles"
                render={props => <ArticleView {...props} />}
              />
              <Route
                exact
                path="/admin/article/edit/:id"
                render={props => <ArticleEditView {...props} />}
              />
              <Route
                exact
                path="/admin/article/photos/:id"
                render={props => <ArticlePhotosView {...props} />}
              />
              <Route
                exact
                path="/admin/article/videos/:id"
                render={props => <ArticleVideosView {...props} />}
              />
              <Route
                exact
                path="/admin/article/choosen/:id"
                render={props => <ArticleByIdView {...props} />}
              />
            </>
          ) : (
            <Redirect to="/" />
          )}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

function App() {
  return (
    <div className="App">
      <Provider {...{ store }}>
        <ToastContainer />
        <Routes />
      </Provider>
    </div>
  );
}

export default App;
