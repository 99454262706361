import { createReducer } from 'redux-starter-kit';
import actions from '../actions/index';

const initState = {
  percent: 0,
  articles: [],
  articlesById: [],
  articlesByUser: [],
};

export default createReducer(initState, {
  [actions.createArticle]: (state, action) => {
    return {
      ...state,
      articles: [...state.articles, action.payload.articles],
    };
  },
  [actions.articleProgressEvent]: (state, action) => {
    return {
      ...state,
      percent: action.payload.percent,
    };
  },
  [actions.getArticles]: (state, action) => {
    return { ...state, articles: action.payload };
  },
  [actions.getArticlesById]: (state, action) => {
    return { ...state, articlesById: action.payload };
  },
  [actions.getArticlesByUser]: (state, action) => {
    return { ...state, articlesByUser: action.payload };
  },
  [actions.getArticlesBySubCategoryId]: (state, action) => {
    return { ...state, articles: action.payload };
  },
  [actions.deleteArticle]: (state, action) => {
    return {
      ...state,
      articles: state.articles.filter(x => x._id !== action.id),
      articlesByUser: state.articlesByUser.filter(x => x._id !== action.id),
    };
  },
  [actions.updateArticle]: (state, action) => {
    return {
      ...state,
      articlesByUser: state.articlesByUser.map(item => {
        if (item._id === action.payload._id) return action.payload;
        else return item;
      }),
      articles: state.articles.map(item => {
        if (item._id === action.payload._id) return action.payload;
        else return item;
      }),
    };
  },
});
